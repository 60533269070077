import { eComInstance } from "../utils/instance";
import {
  PromotionDetailResponse,
  PromotionListResponse,
  PromotionSingleUseListResponse,
} from "../types/dto/response/promotion";
import {
  transformPromotionDetail,
  transformPromotionFormDataRequest,
  transformPromotionList,
  transformPromotionSingleUseResponse,
} from "../types/transformers/promotion";
import { PromotionListRequest } from "../types/dto/request/promotion";
import { PromotionDetailModel, PromotionFormData, PromotionSingleUseListModel } from "../types/model/promotion";
import { getOffset } from "../utils/getPagination";

export async function fetchPromotionList(params: PromotionListRequest) {
  const url = "/promotions";
  return eComInstance
    .get<PromotionListResponse>(url, { params })
    .then((res) => transformPromotionList(res));
}

export async function fetchPromotion(id: string): Promise<PromotionDetailModel> {
  const url = `/promotions/${id}`;
  return eComInstance.get<PromotionDetailResponse>(url).then((res) => transformPromotionDetail(res));
}

export async function createPromotion(promotion: PromotionFormData) {
  const url = "/promotions";
  return eComInstance.post(url, transformPromotionFormDataRequest(promotion)).then((res) => res);
}

export async function updatePromotion(id: string, promotion: PromotionFormData) {
  const url = `/promotions/${id}`;
  return eComInstance.put(url, transformPromotionFormDataRequest(promotion)).then((res) => res);
}

export async function deletePromotion(id: string) {
  const url = `/promotions/${id}`;
  return eComInstance.delete(url).then((res) => res);
}

export async function invalidatePromotion(id: string) {
  const url = `/promotions/${id}/invalidate`;
  return eComInstance.post(url).then((res) => res);
}

export async function fetchPromotionsByBasePriceIds(basePriceIds: string[]): Promise<PromotionDetailModel[]> {
  const url = "/promotions/base-prices";
  return eComInstance
    .get<any>(url, {
      params: { base_price_ids: basePriceIds },
    })
    .then((res) => res.promotions.map((promotion: PromotionDetailResponse) => transformPromotionDetail(promotion)));
}

export async function fetchPromotionCodeList(
  id: string,
  hideRedeemed: boolean,
  page: number = 1,
  pageSize: number = 10
): Promise<PromotionSingleUseListModel> {
  const url = `/promotions/${id}/single-uses`;
  return eComInstance
    .get<PromotionSingleUseListResponse>(url, {
      params: { hide_redeemed: hideRedeemed, limit: pageSize, offset: getOffset(page, pageSize) },
    })
    .then((res) => ({
      list: res.data.map((data) => transformPromotionSingleUseResponse(data)),
      redeemedCount: res.redeemedCount,
    }));
}

export async function downloadPromotionCodeList(id: string, hideRedeemed: boolean): Promise<Blob> {
  const url = `/promotions/${id}/single-uses/csv`;
  return eComInstance.get(url, { params: { hide_redeemed: hideRedeemed }, responseType: "blob" });
}
