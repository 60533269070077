import React from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import styles from "./Indicator.module.scss";

interface Props {
  hasExpired?: boolean;
  aboutToExpire?: boolean;
}

export default function Indicator({ hasExpired = false, aboutToExpire = false }: Props) {
  return (
    <Tooltip placement="top" title={hasExpired ? "Expired" : aboutToExpire ? "About to Expire" : "Active"}>
      <span
        className={classnames(styles.indicator, hasExpired && styles.hasExpired, aboutToExpire && styles.aboutToExpire)}
      />
    </Tooltip>
  );
}
