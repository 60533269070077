import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {
  PromotionDetailResponse,
  PromotionListResponse,
  PromotionResponse,
  PromotionSingleUseResponse,
} from "../dto/response/promotion";
import {
  CodeUsageType,
  DiscountType,
  PromotionDetailModel,
  PromotionFormData,
  PromotionListModel,
  PromotionModel,
  PromotionSingleUseModel,
  PromotionStatus,
} from "../model/promotion";
import { PromotionCreateRequest } from "../dto/request/promotion";
import { parseContent } from "../../utils/richTextEditor";
import { LocaleType, supportLocalTabs } from "../../utils/locale";
import { ContentBase } from "../model/description";

export function transformPromotion(response: PromotionResponse): PromotionModel {
  return {
    id: response.id,
    name: response.name,
    code: response.code,
    startAt: response.start_at,
    endAt: response.end_at,
    discountType: response.discount_type,
    percentageDiscount: response.percentage_discount,
    currencyCode: response.currency_code,
    amountDiscount: response.amount_discount,
    updatedAt: response.updated_at,
    status: response.status as PromotionStatus,
    basePrices: response.base_prices,
    promotionCodeTexts: response.code_text,
    codeUsage: response.is_single_use ? CodeUsageType.SINGLE_USE : CodeUsageType.REGULAR,
    quantity: response.single_use_quantity,
  };
}

export function transformPromotionList(response: PromotionListResponse): PromotionListModel {
  return {
    list: response.data.map(transformPromotion),
    total: response.count,
  };
}

function transformDescriptionDisclosure(content?: { [key in LocaleType]: string }) {
  return content
    ? supportLocalTabs.map((tab) => ({
        label: tab.label,
        key: tab.key,
        locale: tab.locale,
        content: parseContent(content?.[tab.locale] ?? ""),
        required: false,
      }))
    : undefined;
}

export function transformPromotionDetail(response: PromotionDetailResponse): PromotionDetailModel {
  return {
    ...transformPromotion(response),
    basePrices: response.base_prices,
    billingCycle: response.billing_cycle,
    isRestrictToNew: response.is_restrict_to_new,
    isStackable: response.is_stackable,
    isSubscription: response.is_subscription,
    isActiveBefore: response.is_active_before,
    descriptionDisclosures: response.description_disclosures
      ? response.description_disclosures?.map((descriptionDisclosure) => ({
          billingPlanId: descriptionDisclosure.billing_plan_id,
          billingPlanName: descriptionDisclosure.billing_plan_name,
          description: transformDescriptionDisclosure(descriptionDisclosure.description),
          disclosure: transformDescriptionDisclosure(descriptionDisclosure.disclosure),
        }))
      : undefined,
    countries: response.countries?.map((country) => ({
      displayName: country.display_name,
      id: country.id,
      iso2: country.iso_2,
    })),
    basePlan: response.buy1_gift1
      ? {
          ...omit(response.buy1_gift1?.buy_variant, ["billing_plan_id", "billing_plan_name"]),
          billingPlanId: response.buy1_gift1?.buy_variant.billing_plan_id,
          billingPlanName: response.buy1_gift1?.buy_variant.billing_plan_name,
        }
      : undefined,
    giftPlan: response.buy1_gift1
      ? {
          ...omit(response.buy1_gift1?.gift_variant, ["billing_plan_id", "billing_plan_name"]),
          billingPlanId: response.buy1_gift1?.gift_variant.billing_plan_id,
          billingPlanName: response.buy1_gift1?.gift_variant.billing_plan_name,
        }
      : undefined,
  };
}

export function transformPromotionFormDataRequest(formData: PromotionFormData): PromotionCreateRequest {
  const request: PromotionCreateRequest = {
    name: formData.name.trim(),
    start_at: formData.timeRange[0],
    end_at: formData.timeRange[1],
    code: formData.code.trim(),
    is_subscription: formData.isSubscription,
    discount_type: formData.discountType,
    percentage_discount:
      formData.discountType === DiscountType.PERCENTAGE ? Number(formData.percentageDiscount) : undefined,
    currency_code: formData.currencyCode,
    amount_discount: formData.discountType === DiscountType.AMOUNT ? Number(formData.amountDiscount) : undefined,
    base_price_ids: formData.basePriceIds,
    is_stackable: false,
    country_ids: formData.countryIds || [],
    buy1_gift1:
      formData.discountType === DiscountType.BUY_ONE_GIFT_ONE
        ? {
            buy_variant_id: formData.basePlanId!,
            gift_variant_id: formData.giftPlanId!,
          }
        : undefined,
    code_text: {
      [LocaleType.English]: formData.codeTextEn,
      [LocaleType.Spanish]: formData.codeTextEs,
    },
    is_single_use: formData.codeUsage === CodeUsageType.SINGLE_USE,
  };

  const generateDescriptionOrDisclosure = (data?: ContentBase[]) => {
    if (!data || isEmpty(data)) {
      return undefined;
    }

    return data.reduce((acc, cur) => {
      acc[cur.locale] = JSON.stringify(cur.content);
      return acc;
    }, {} as { [key in LocaleType]: string });
  };

  if (formData.isSubscription || formData.discountType === DiscountType.BUY_ONE_GIFT_ONE) {
    request.description_disclosures = formData.descriptionDisclosures?.map((descriptionDisclosure) => ({
      billing_plan_id: descriptionDisclosure.billingPlanId,
      billing_plan_name: descriptionDisclosure.billingPlanName,
      description: generateDescriptionOrDisclosure(descriptionDisclosure.description),
      disclosure: generateDescriptionOrDisclosure(descriptionDisclosure.disclosure),
    }));
  }

  if (formData.isSubscription) {
    request.is_restrict_to_new = formData.isRestrictToNew;
    request.billing_cycle = Number(formData.billingCycle);
  }

  if (formData.codeUsage === CodeUsageType.SINGLE_USE) {
    request.quantity = Number(formData.quantity);
  }

  return request;
}

export function transformPromotionSingleUseResponse(response: PromotionSingleUseResponse): PromotionSingleUseModel {
  return {
    code: response.code,
    status: response.status,
    email: response.customer_email,
    customerId: response.customer_id,
    date: response.applied_at ? moment(response.applied_at).local().format("YYYY-MM-DD HH:mm:ss") : undefined,
  };
}
