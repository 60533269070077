import moment from "moment";
import {
  EcomCurrencyPrice,
  EComProductLanguageResponse,
  EComProductListResponse,
  EComProductLogListResponse,
  EComProductResponse,
  EComProductVariantsResponse,
} from "types/dto/response/eComProduct";
import { isUndefined } from "lodash";
import {
  CourseType,
  EComProductInfo,
  EComProductLanguage,
  EComProductListModel,
  EComProductLogListModel,
  EComProductStatusType,
  NewPlanFormFieldValues,
  ProductPlan,
  ProductPlanDetails,
} from "../../model/eComProduct";
import { EComProductListRequest, ProductCreateRequest } from "../../dto/request/eComProduct";

export function transformComProductLanguages(languageResponses: EComProductLanguageResponse[]): EComProductLanguage[] {
  return languageResponses?.map(({ id, language_id, title, subtitle }) => ({
    id,
    languageId: language_id,
    name: title,
    courseType: subtitle as CourseType,
  }));
}

export function transformEComProductListRequest({
  pid,
  status,
  limit,
  offset,
  order,
  lastModifiedDateFrom,
  lastModifiedDateTo,
  basePriceIds,
  languageId,
  courseType,
  billingPlanId,
  type,
}: EComProductListRequest) {
  return {
    pid,
    status,
    limit,
    offset,
    order,
    type,
    updated_at_from: lastModifiedDateFrom && moment(lastModifiedDateFrom).toISOString(),
    updated_at_to: lastModifiedDateTo && moment(lastModifiedDateTo).toISOString(),
    base_price_id: basePriceIds,
    language_id: languageId,
    product_subtitle: courseType,
    billing_plan_id: billingPlanId,
  };
}

export function transformComProductList(eComProductListResponses: EComProductListResponse): EComProductListModel {
  return {
    list: eComProductListResponses.variants?.map((item) => ({
      id: item.id,
      pid: item.pid,
      language: item.product.title,
      planName: item.title,
      courseType: item.product.subtitle,
      status: item.status,
      basePriceName: item.base_price?.name,
      updatedAt: item.updated_at,
      productId: item.product_id,
      basePricesId: item.base_price_id,
      contentProductType: item.content_product_type,
    })),
    total: eComProductListResponses.count,
  };
}

export function transformProductCreateRequest(
  { languageName, isPremium, languageId }: EComProductInfo,
  productPlans: ProductPlan[]
): ProductCreateRequest {
  return {
    product: {
      language: languageName,
      type: isPremium ? CourseType.PREMIUM : CourseType.STANDARD,
      language_id: languageId,
    },
    plans: productPlans.map(
      ({
        name,
        billingPlanId,
        contentProductType,
        basePriceId,
        joinUpsell,
        descTemplateId,
        productImage,
        isbn,
        contentProductId,
        courseLevels,
        freeTrialType,
        freeTrialUsageLimit,
      }) => ({
        name,
        base_price_id: basePriceId,
        content_product_type: contentProductType,
        billing_plan_id: billingPlanId,
        join_upsell: joinUpsell,
        desc_template_id: descTemplateId,
        product_image: productImage,
        isbn,
        content_product_id: contentProductId,
        course_levels: courseLevels,
        free_trial_type: freeTrialType,
        free_trial_usage_limit: freeTrialUsageLimit,
      })
    ),
  };
}

export function transformCreateVariantRequest(newPlan: ProductPlan) {
  return {
    name: newPlan.name,
    base_price_id: newPlan.basePriceId,
    content_product_type: newPlan.contentProductType,
    billing_plan_id: newPlan.billingPlanId,
    free_trial_type: isUndefined(newPlan.freeTrialType) ? null : newPlan.freeTrialType,
    free_trial_usage_limit: isUndefined(newPlan.freeTrialUsageLimit) ? null : newPlan.freeTrialUsageLimit,
    join_upsell: newPlan.joinUpsell,
    desc_template_id: newPlan.descTemplateId,
    product_image: newPlan.productImage || null,
    isbn: newPlan.isbn,
    content_product_id: newPlan.contentProductId,
    course_levels: newPlan.courseLevels,
  };
}

function transformCurrencyPrice(currencyPrices: EcomCurrencyPrice[]) {
  if (!currencyPrices || currencyPrices.length === 0) {
    return "Currency prices not available";
  }

  return currencyPrices.map(({ amount, currency_code }) => `${currency_code.toUpperCase()} ${amount}`).join(" / ");
}

function transformProductVariant(variant: EComProductVariantsResponse): ProductPlanDetails {
  const recurlyCouponsWording = variant.coupons.length ? ` (${variant.coupons.map((c) => c.code).join(", ")})` : "";
  return {
    pid: variant.pid,
    name: variant.title,
    contentProductType: variant.content_product_type,
    billingPlanId: variant.billing_plan_id,
    billingPlanName: variant.billing_plan?.name ?? "",
    billingCycle: variant.billing_plan?.cycle ?? "",
    billingQuantity: variant.billing_plan?.quantity ?? 0,
    freeTrialType: variant.free_trial_type,
    freeTrialUsageLimit: variant.free_trial_usage_limit,
    joinUpsell: variant.join_upsell,
    descTemplateId: variant.desc_template_id,
    descTemplateName: variant.description?.name ?? "",
    descTemplateContent: variant.description?.content ?? "",
    productImage: variant.product_image,
    isbn: variant.metadata?.isbn ?? "",
    contentProductId: variant.metadata?.content_product_id ?? "",
    basePriceId: variant.base_price.id,
    basePriceName: variant.base_price.name,
    basePriceHelpText: transformCurrencyPrice(variant.base_price.currency_prices),
    planId: variant.id,
    status: variant.status as EComProductStatusType,
    promotionCode:
      variant.base_price.promotions
        .map((promotion) => `${promotion.code}${promotion.is_single_use ? "******" : ""}`)
        .concat(
          (variant.buy1_gift1_promotions || [])
            .filter((p) => p.promotion)
            .map((promotion) => `${promotion.promotion.code}${promotion.promotion.is_single_use ? "******" : ""}`)
        )
        .join(", ") + recurlyCouponsWording,
  };
}

export function transformProductVariantForSaveOffer(variants: any) {
  if (variants && Array.isArray(variants)) {
    return variants.map((variant: any) => ({
      id: variant.id,
      title: variant.title,
      pid: variant.pid,
      basePriceId: variant.base_price_id,
      basePriceWithCountries: variant.base_price_with_countries.map((item: any) => ({
        amount: item.amount,
        countries: item.countries.map((country: any) => ({
          displayName: country.display_name,
          iso2: country.iso_2,
        })),
        currency: {
          code: item.currency.code,
          symbol: item.currency.symbol,
        },
      })),
      billingPlan: variant.billing_plan
        ? {
            id: variant.billing_plan.id,
            name: variant.billing_plan.name,
          }
        : null,
      promotions: variant.promotions
        ? variant.promotions.map((promotion: any) => ({
            id: promotion.id,
            name: promotion.name,
            code: promotion.code,
            isSubscription: promotion.is_subscription,
            billingCycle: promotion.billing_cycle,
            discountType: promotion.discount_type,
            percentageDiscount: promotion.percentage_discount,
            amountDiscount: promotion.amount_discount,
            currencyCode: promotion.currency_code,
            countries: promotion.countries
              ? promotion.countries.map((country: any) => ({
                  id: country.id,
                  iso2: country.iso_2,
                  name: country.name,
                  displayName: country.display_name,
                }))
              : [],
          }))
        : [],
    }));
  } else {
    return null;
  }
}

export function transformProduct(eComProductListResponses: EComProductResponse) {
  return {
    language: eComProductListResponses.title,
    courseType: eComProductListResponses.subtitle,
    plans: eComProductListResponses.variants
      .sort((p1, p2) => moment(p2.created_at).valueOf() - moment(p1.created_at).valueOf())
      .map(transformProductVariant),
    languageId: eComProductListResponses.language_id,
  };
}

export const convertToProductPlan = (value: NewPlanFormFieldValues): ProductPlan => ({
  name: value.name,
  basePriceId: value.basePriceId,
  contentProductType: value.contentProductType,
  billingPlanId: value.billingPlanId,
  freeTrialType: value.freeTrialType,
  freeTrialUsageLimit: value.freeTrialUsageLimit,
  joinUpsell: value.joinUpsell,
  descTemplateId: value.descTemplateId,
  productImage: value.productImage,
  isbn: value.isbn,
  contentProductId: value.contentProductId,
  courseLevels: value.courseLevels,
});

export function transformProductLogList(logs: EComProductLogListResponse): EComProductLogListModel {
  return {
    total: logs.count,
    list: logs.data.map((log) => ({
      pid: log.pid,
      action: log.action,
      prevValue: log.prev,
      currentValue: log.current,
      updatedBy: log.updated_by,
      updatedAt: log.updated_at,
    })),
  };
}
