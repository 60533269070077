import _ from "lodash";
import moment from "moment";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { DATETIME_FORMAT, EMPTY, VOUCHER_TYPE_MAP } from "constants/common";
import { VoucherGroupListResponse } from "types/dto/response/voucher";
import Indicator from "./Indicator";

function useColumns(sortKey: string, sortOrder: SortOrder, isActive?: boolean): ColumnType<VoucherGroupListResponse>[] {
  return [
    {
      title: "Organization",
      dataIndex: "organizationName",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Invoice number",
      dataIndex: "invoice",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Vouchers description",
      dataIndex: "description",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Voucher type",
      dataIndex: "voucherType",
      ellipsis: true,
      render: (value) => VOUCHER_TYPE_MAP.get(value) || EMPTY,
    },
    {
      title: "Total licenses",
      dataIndex: "voucherAmount",
      ellipsis: true,
      render: (value) => value ?? EMPTY,
    },
    {
      title: "Expired at",
      dataIndex: "expirationDate",
      key: "expirationDate",
      filters: [
        { text: "Active", value: true },
        { text: "Expired", value: false },
      ],
      filteredValue: _.isNil(isActive) ? null : [isActive],
      onFilter: (value, record) => {
        return record.isActive === value;
      },
      defaultSortOrder: sortKey === "expirationDate" ? sortOrder : undefined,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value, record) => {
        return (
          <div>
            <Indicator hasExpired={record.hasExpired} aboutToExpire={record.aboutToExpire} />
            {value ? moment(value).format("YYYY-MM-DD") : EMPTY}
          </div>
        );
      },
    },
    {
      title: "Modified at",
      dataIndex: "lastModifiedDate",
      ellipsis: true,
      defaultSortOrder: sortKey === "lastModifiedDate" ? sortOrder : undefined,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}

export default useColumns;
