import { eComInstance } from "../utils/instance";
import { SubscriptionResponse, SubscriptionUpsellDowngradeListResponse } from "../types/model/subscriptions";
import { transformSubscriptions, transformUpsellDowngradeItem } from "../types/transformers/subscriptions";
import { PromotionDetailResponse } from "../types/dto/response/promotion";
import { transformPromotionDetail } from "../types/transformers/promotion";
import { CountryResponse } from "../types/dto/response/basePrice";
import { transformCountry } from "../types/transformers/basePrice";

export async function fetchSubscriptions(auth0Id: string) {
  const url = "/subscriptions";
  return eComInstance
    .get<SubscriptionResponse[]>(url, { params: { auth0Id } })
    .then((res) => res.map((sub) => transformSubscriptions(sub)));
}

export async function cancelSubscription(subscriptionId: string, auth0Id: string, source: string) {
  const url = `/subscriptions/${subscriptionId}/actions/cancel`;
  return eComInstance.post(url, null, {
    params: { auth0Id, source },
  });
}

export async function revokeSubscription(subscriptionId: string, auth0Id: string, source: string) {
  const url = `/subscriptions/${subscriptionId}/actions/revoke`;
  return eComInstance.post(url, null, {
    params: { auth0Id, source },
  });
}

export async function pauseSubscription(subscriptionId: string, auth0Id: string, pauseDays: number, source: string) {
  const url = `/subscriptions/${subscriptionId}/actions/pause`;
  return eComInstance.post(
    url,
    { paused_days: pauseDays },
    {
      params: { auth0Id, source },
    }
  );
}

export async function fetchDowngradePlanList(pid: string) {
  const url = `/variants/${pid}/downgrades`;
  return eComInstance
    .get<SubscriptionUpsellDowngradeListResponse[]>(url)
    .then((res) => res.map((item) => transformUpsellDowngradeItem(item)));
}

export async function fetchUpsellPlanList(pid: string) {
  const url = `/variants/${pid}/upsells`;
  return eComInstance
    .get<SubscriptionUpsellDowngradeListResponse[]>(url)
    .then((res) => res.map((item) => transformUpsellDowngradeItem(item)));
}

export async function submitSwitchPlan(
  auth0Id: string,
  email: string,
  subscriptionId: string,
  originPid: string,
  newPid: string
) {
  const url = `/subscriptions/${subscriptionId}/send-switch-plan-link`;
  return eComInstance.post(url, {
    auth0Id,
    email,
    original_pid: originPid,
    pid: newPid,
  });
}

export async function fetchSubscriptionPromotions(subscriptionId: string) {
  const url = `/subscriptions/${subscriptionId}/promotions`;
  return eComInstance
    .get<{
      promotions: PromotionDetailResponse[];
      countries: CountryResponse[];
    }>(url)
    .then((res) => {
      return {
        promotions: res.promotions.map((item) => transformPromotionDetail(item)),
        countries: transformCountry(res.countries),
      };
    });
}

export async function submitApplyPromoCode(subscriptionId: string, promoCode: string, auth0Id: string) {
  const url = `/subscriptions/${subscriptionId}/actions/apply-promotion/${promoCode}`;
  return eComInstance.post(url, { auth0Id });
}

export async function resumeSubscription(subscriptionId: string, auth0Id: string, source: string) {
  const url = `/subscriptions/${subscriptionId}/actions/resume`;
  return eComInstance.post(url, null, {
    params: { auth0Id, source },
  });
}

export async function extendBillingDate(subscriptionId: string, extendDays: number) {
  const url = `/subscriptions/${subscriptionId}/actions/extend`;
  return eComInstance.post(url, { extend_days: extendDays });
}
