import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { ColumnType, TableProps } from "antd/es/table";
import { useHistory, useRouteMatch } from "react-router";
import { Card, Input, Row, Space, Table } from "antd";
import * as eComPromotionAPI from "services/promotion";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import omit from "lodash/omit";
import { PromotionListModel, PromotionModel, PromotionStatus } from "../../../../types/model/promotion";
import AddButton from "../../../../components/AddButton";
import ResizableTitle from "../../../../components/ResizableTitle";
import { getTotalDisplay } from "../../../../utils/getTotalDisplay";
import styles from "./index.module.scss";
import getPromotionTableColumns from "./getPromotionTableColumns";
import DropdownInput from "../../../../components/DropdownInput";
import { getOffset } from "../../../../utils/getPagination";
import { PromotionListRequest } from "../../../../types/dto/request/promotion";
import useAppAuth0 from "../../../../hooks/useAppAuth0";

const statusOptions = [
  { label: "Active", value: PromotionStatus.ACTIVE },
  { label: "Not started", value: PromotionStatus.NOT_STARTED },
  { label: "Expired", value: PromotionStatus.EXPIRED },
];

function PromotionList() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 10),
    status: withDefault(ArrayParam, undefined),
    searchText: withDefault(StringParam, undefined),
    order: withDefault(StringParam, "-updated_at"),
  });
  const history = useHistory();
  const { user } = useAppAuth0();
  const [selectStatus, setSelectStatus] = useState<PromotionStatus[]>([]);
  const [isPromotionListPending, setIsPromotionListPending] = useState(false);
  const [PromotionList, setPromotionList] = useState<PromotionListModel>({} as PromotionListModel);
  const [searchText, setSearchText] = useState(query.searchText);
  const columns = getPromotionTableColumns();
  const [tableColumns, setTableColumns] = useState<ColumnType<PromotionModel>[]>(columns);
  const { path } = useRouteMatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleInputPressEnter = () => {
    setQuery({
      ...query,
      searchText: searchText?.trim(),
      page: searchText?.trim() ? 1 : query.page,
    });
  };

  const refreshPromotionList = useCallback(() => {
    setIsPromotionListPending(true);
    eComPromotionAPI
      .fetchPromotionList({
        ...omit(query, ["page"]),
        offset: getOffset(query.page, query.limit),
      } as PromotionListRequest)
      .then((res) => {
        setPromotionList(res);
      })
      .finally(() => {
        setIsPromotionListPending(false);
      });
  }, [query]);

  useEffect(() => {
    refreshPromotionList();
    setSearchText(query.searchText);
  }, [query, refreshPromotionList]);

  const handleResize = (index: number) => (e: any, { size }: any) => {
    const nextColumns = [...tableColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    setTableColumns(nextColumns);
  };

  const handleStatusChange = (statuses: PromotionStatus[]) => {
    setSelectStatus(statuses);
    setQuery({ ...query, status: statuses, page: 1 });
  };

  const columnsToDisplay = tableColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<PromotionModel>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>),
  }));

  const handleOnRowClick: TableProps<PromotionModel>["onRow"] = (record) => ({
    onClick: () =>
      history.push({
        pathname: `${path}/${record.id}`,
      }),
  });

  return (
    <Card
      title="Promotion list"
      extra={user.canCreatePromotion && <AddButton text={"+ Add new Promotion"} />}
      className="page-container"
    >
      <Row justify={"space-between"} className={styles.filter}>
        <Space>
          <DropdownInput
            options={statusOptions}
            placeholder="All statuses"
            value={selectStatus}
            onChange={(statuses) => handleStatusChange(statuses as PromotionStatus[])}
          />
          <Input
            allowClear
            value={searchText}
            onChange={handleInputChange}
            onBlur={() => setSearchText((prevState) => prevState?.trim())}
            onPressEnter={handleInputPressEnter}
            className={styles.promotionNameInput}
            prefix={<SearchOutlined />}
            placeholder="Search promotion code or applied base price "
          />
        </Space>
      </Row>

      <Table<PromotionModel>
        loading={{ spinning: isPromotionListPending }}
        components={{ header: { cell: ResizableTitle } }}
        rowKey={(record) => record.id}
        columns={columnsToDisplay}
        dataSource={PromotionList.list}
        scroll={{ scrollToFirstRowOnChange: true, x: "100%", y: "max-content" }}
        pagination={{
          showTotal: getTotalDisplay,
          current: query.page,
          pageSize: query.limit,
          total: PromotionList.total,
          defaultCurrent: 1,
          onChange: (page, pageSize) => {
            setQuery({
              ...query,
              page,
              limit: pageSize,
              order: query.order,
            });
          },
        }}
        onRow={handleOnRowClick}
      />
    </Card>
  );
}

export default PromotionList;
