import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Space, Spin, Table } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { noop } from "lodash";
import { ColumnType } from "antd/lib/table";
import { useParams } from "react-router-dom";
import { EComModal } from "../../../../components/EComModal";
import styles from "./PromotionCodeList.module.scss";
import { EMPTY } from "../../../../constants/common";
import * as promotionAPI from "../../../../services/promotion";
import { PromotionSingleUseListModel, PromotionSingleUseModel } from "../../../../types/model/promotion";
import { saveFile } from "../../../../utils/saveFile";
import { message } from "../../../../components";

interface IProps {
  code: string;
  count: number;
}

interface TableConfig {
  current?: number;
  pageSize?: number;
  hideRedeem: boolean;
}

const columns: ColumnType<PromotionSingleUseModel>[] = [
  {
    title: "Promotion code",
    dataIndex: "code",
    width: "25%",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "25%",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
  {
    title: "Redeemed email",
    dataIndex: "email",
    width: "25%",
    ellipsis: true,
    render: (_, record) => {
      if (!record.customerId) {
        return EMPTY;
      }
      return <a href={`/customers/b2c/${record.customerId}`}>{record.email ?? "Anonymous user"}</a>;
    },
  },
  {
    title: "Redeemed date",
    dataIndex: "date",
    width: "25%",
    ellipsis: true,
    render: (value) => value || EMPTY,
  },
];

const DEFAULT_PAGE_CONFIG = {
  current: 1,
  pageSize: 10,
  hideRedeem: false,
};

export default function PromotionCodeList({ count, code }: IProps) {
  const { id } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataSource, setDataSource] = useState<PromotionSingleUseListModel>();
  const [pageConfig, setPageConfig] = useState<TableConfig>(DEFAULT_PAGE_CONFIG);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isModalVisible) {
      requestData();
    }
  }, [isModalVisible, JSON.stringify(pageConfig)]);

  function requestData() {
    setIsLoadingData(true);
    promotionAPI
      .fetchPromotionCodeList(id, pageConfig.hideRedeem, pageConfig.current, pageConfig.pageSize)
      .then((res) => setDataSource(res))
      .finally(() => setIsLoadingData(false));
  }

  function closeModal() {
    setConfirmLoading(false);
    setIsModalVisible(false);
  }

  function onViewClick() {
    setIsModalVisible(true);
    setPageConfig(DEFAULT_PAGE_CONFIG);
  }

  function onDownload() {
    setConfirmLoading(true);
    promotionAPI
      .downloadPromotionCodeList(id, pageConfig.hideRedeem)
      .then((content: Blob) => saveFile(content, `promotion_code_list_${code}.csv`))
      .catch(message.error)
      .finally(closeModal);
  }

  function onPageChange(current: number, pageSize?: number) {
    setPageConfig({ ...pageConfig, current, pageSize });
  }

  function onHideRedeemChange(e: CheckboxChangeEvent) {
    setPageConfig({ ...pageConfig, hideRedeem: e.target.checked });
  }

  return (
    <>
      <Button ref={buttonRef} type={"link"} className={styles.viewCodesButton} onClick={onViewClick}>
        View and download a batch of promotion codes here
      </Button>
      <EComModal
        visible={isModalVisible}
        title="Promotion code list"
        onCancel={closeModal}
        afterClose={() => buttonRef?.current?.blur()}
        okText={""}
        confirmLoading={confirmLoading}
        onOk={noop}
        width={"75%"}
        footer={[
          <Button
            type="primary"
            shape={"round"}
            style={{ minWidth: "92px" }}
            loading={confirmLoading}
            onClick={onDownload}
          >
            Download
          </Button>,
        ]}
      >
        <Spin spinning={isLoadingData} size={"large"}>
          <Space className={styles.subtitleContainer} size={"large"}>
            {`There are ${dataSource?.redeemedCount ?? 0}/${count} promotion codes redeemed already.`}
            <Checkbox checked={pageConfig.hideRedeem} onChange={onHideRedeemChange}>
              Hide redeemed codes
            </Checkbox>
          </Space>
          <Table
            className={styles.table}
            columns={columns}
            dataSource={dataSource?.list}
            rowKey={(row) => row.code}
            pagination={{ ...pageConfig, total: count, onChange: onPageChange }}
          />
        </Spin>
      </EComModal>
    </>
  );
}
