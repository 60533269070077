import React, { useEffect, useState } from "react";
import { Card, Form, Spin } from "antd";
import moment from "moment/moment";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import { DATETIME_FORMAT_WITH_00_SECOND, LEAVE_CONFIRMATION } from "../../../../constants/common";
import ViewButtons from "./ViewButtons";
import EditButtons from "./EditButtons";
import * as promotionAPI from "../../../../services/promotionV2";
import { DescriptionDisclosure } from "../../../../types/model/promotion";
import {
  PromotionBaseDetailsModel,
  PromotionWithBuyOneGiftOneDetailsModal,
  PromotionWithBuyOneGiftOneFormData,
  PromotionWithRecurlyDetailsModal,
  PromotionWithRecurlyFormData,
} from "../../../../types/model/promotionV2";
import PromotionDetailsViewForEComSource from "./PromotionDetailsViewForEComSource";
import PromotionDetailsViewForRecurlySource from "./PromotionDetailsViewForRecurlySource";
import { PromotionForm, PromotionSource } from "../components/PromotionForm";
import { getDisplayDiscount, getDisplayPromotionDiscountType, getDisplayPromotionStatus } from "../util";
import { message, Modal } from "../../../../components";
import { bold } from "../../../../utils";
import { transformDescriptionDisclosure } from "../../promotion/util";

export default function PromotionDetails() {
  const { id } = useParams<{
    id: string;
  }>();
  const promotionSource = new URLSearchParams(useLocation().search).get("source");
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();
  const [promotionDetails, setPromotionDetails] = useState<PromotionBaseDetailsModel>();
  const [descriptionDisclosures, setDescriptionDisclosures] = useState<DescriptionDisclosure[]>([]);

  useEffect(() => {
    getAndSetPromotionDetails();
  }, []);

  const setFormValuesForBuyOneGiftOne = (promotionDetails: PromotionWithBuyOneGiftOneDetailsModal) => {
    form.setFieldsValue({
      ...promotionDetails,
      type: getDisplayPromotionDiscountType(promotionDetails.discountType),
      source: PromotionSource.ECOM,
      timeRange: [moment(promotionDetails.startedAt).local(), moment(promotionDetails.endedAt).local()],
      basePlanPid: promotionDetails.buyPlan.pid,
      giftPlanPid: promotionDetails.giftPlan.pid,
      codeTextEn: promotionDetails.codeText?.en,
      codeTextEs: promotionDetails.codeText?.es,
    });
  };

  const setFormValuesForRecurly = (promotionDetails: PromotionWithRecurlyDetailsModal) => {
    form.setFieldsValue({
      ...promotionDetails,
      source: PromotionSource.RECURLY,
      timeRange: [
        moment(promotionDetails.startedAt).local(),
        promotionDetails.endedAt ? moment(promotionDetails.endedAt).local() : null,
      ],
      type: getDisplayPromotionDiscountType(promotionDetails.discountType),
      discount: getDisplayDiscount(
        promotionDetails.discountType,
        promotionDetails.percent,
        promotionDetails.currencies
      ),
      displayPromotionCode: promotionDetails.code,
      basePriceIds: promotionDetails.appliedBasePrices?.map((basePrice) => basePrice.id),
      countryIds: promotionDetails.appliedCountries?.map((country) => country.id),
      status: getDisplayPromotionStatus(promotionDetails.status),
      codeTextEn: promotionDetails.codeText?.en,
      codeTextEs: promotionDetails.codeText?.es,
    });
  };

  function fetchPromotionOperations<T extends PromotionBaseDetailsModel>(
    fetcher: (id: string) => Promise<T>,
    id: string
  ) {
    fetcher(id)
      .then((res) => {
        setName(res.name);
        setPromotionDetails(res);
        setDescriptionDisclosures(res.descriptionDisclosures || []);

        if (promotionSource === PromotionSource.ECOM) {
          setFormValuesForBuyOneGiftOne((res as unknown) as PromotionWithBuyOneGiftOneDetailsModal);
        }
        if (promotionSource === PromotionSource.RECURLY) {
          setFormValuesForRecurly((res as unknown) as PromotionWithRecurlyDetailsModal);
        }
      })
      .finally(() => setLoading(false));
  }

  const getAndSetPromotionDetails = () => {
    setLoading(true);
    if (promotionSource === PromotionSource.ECOM) {
      fetchPromotionOperations<PromotionWithBuyOneGiftOneDetailsModal>(
        promotionAPI.fetchPromotionWithBuyOneGiftOneDetails,
        id
      );
    }

    if (promotionSource === PromotionSource.RECURLY) {
      fetchPromotionOperations<PromotionWithRecurlyDetailsModal>(promotionAPI.fetchPromotionWithRecurlyDetails, id);
    }
  };

  function updatePromotionOperations<T>(
    updater: (id: string, updatePromotion: T) => Promise<any>,
    id: string,
    updatePromotion: T
  ) {
    updater(id, updatePromotion).then(() => {
      setEditing(false);
      form.resetFields();
      message.success("Promotion updated successfully.").then();
      getAndSetPromotionDetails();
    });
  }

  const handleUpdate = () => {
    form.validateFields().then((values) => {
      Modal.confirm({
        title: "Edit Promotion",
        okText: "Confirm",
        content: bold`Are you sure you want to edit the ${name}? The ongoing subscription that already applied the promotion will be affected. Please confirm that you want to proceed.`,
        onOk: () => {
          setLoading(true);
          if (promotionSource === PromotionSource.ECOM) {
            updatePromotionOperations<PromotionWithBuyOneGiftOneFormData>(
              promotionAPI.updatePromotionWithBuyOneGiftOne,
              id,
              {
                ...values,
                description: transformDescriptionDisclosure(values, "DescriptionContent"),
                disclosure: transformDescriptionDisclosure(values, "DisclosureContent"),
                timeRange: values.timeRange.map((date: moment.Moment) => date.format(DATETIME_FORMAT_WITH_00_SECOND)),
              }
            );
          }

          if (promotionSource === PromotionSource.RECURLY) {
            updatePromotionOperations<PromotionWithRecurlyFormData>(promotionAPI.updatePromotionWithRecurly, id, {
              ...values,
              descriptionDisclosures,
            });
          }
        },
      });
    });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Confirm",
      okText: "OK",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setDescriptionDisclosures(promotionDetails?.descriptionDisclosures || []);
        form.resetFields();
        if (promotionSource === PromotionSource.RECURLY) {
          setFormValuesForRecurly(promotionDetails as PromotionWithRecurlyDetailsModal);
        }
        if (promotionSource === PromotionSource.ECOM) {
          setFormValuesForBuyOneGiftOne(promotionDetails as PromotionWithBuyOneGiftOneDetailsModal);
        }
        setEditing(false);
      },
    });
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete Promotion",
      okText: "Confirm",
      content:
        promotionDetails &&
        bold`Are you sure you want to delete the ${name}? This action will permanently delete the promotion. Please confirm that you want to proceed.`,
      onOk: () =>
        promotionAPI.deletePromotion(id).then(() => {
          history.push("/marketing/recurly-promotion");
          message.success("Promotion deleted successfully.").then();
        }),
    });
  };

  return (
    <Card
      className="page-container"
      title={name}
      extra={
        editing ? (
          <EditButtons onSave={handleUpdate} onCancel={handleCancel} />
        ) : (
          <ViewButtons
            isDeleteDisabled={!promotionDetails?.canBeDeleted}
            onDelete={handleDelete}
            onEdit={() => setEditing(true)}
          />
        )
      }
    >
      <Spin spinning={loading} size="large">
        {editing ? (
          <>
            <Prompt when={editing} message={LEAVE_CONFIRMATION} />
            <PromotionForm
              form={form}
              descriptionDisclosures={descriptionDisclosures}
              updateDescriptionDisclosures={setDescriptionDisclosures}
              isEditing={true}
            />
          </>
        ) : (
          <>
            {promotionSource === PromotionSource.ECOM && (
              <PromotionDetailsViewForEComSource
                promotionDetails={promotionDetails as PromotionWithBuyOneGiftOneDetailsModal}
              />
            )}
            {promotionSource === PromotionSource.RECURLY && (
              <PromotionDetailsViewForRecurlySource
                promotionDetails={promotionDetails as PromotionWithRecurlyDetailsModal}
              />
            )}
          </>
        )}
      </Spin>
    </Card>
  );
}
