import { useHistory } from "react-router";
import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import moment from "moment";
import { Prompt } from "react-router-dom";
import * as eComPromotionAPI from "services/promotion";
import { DATETIME_FORMAT_WITH_00_SECOND, LEAVE_CONFIRMATION } from "../../../../constants/common";
import { PromotionForm } from "../components/PromotionForm";
import { message } from "../../../../components";
import { DescriptionDisclosure, DiscountType } from "../../../../types/model/promotion";
import { transformDescriptionDisclosure } from "../util";

function AddNewPromotion() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [descriptionDisclosures, setDescriptionDisclosures] = useState<DescriptionDisclosure[]>([]);
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleCreate = () =>
    form.validateFields().then((values) => {
      setIsSaveLoading(true);
      eComPromotionAPI
        .createPromotion({
          ...values,
          descriptionDisclosures:
            values.discountType === DiscountType.BUY_ONE_GIFT_ONE
              ? [
                  {
                    description: transformDescriptionDisclosure(values, "DescriptionContent"),
                    disclosure: transformDescriptionDisclosure(values, "DisclosureContent"),
                  },
                ]
              : descriptionDisclosures,
          timeRange: values.timeRange.map((date: moment.Moment) => date.format(DATETIME_FORMAT_WITH_00_SECOND)),
        })
        .then(() => {
          setFormIsHalfFilledOut(false);
          form.resetFields();
          history.push("/marketing/promotions");
          message.success("Promotion added successfully.");
        })
        .finally(() => setIsSaveLoading(false));
    });

  const handleCancel = () => {
    setDescriptionDisclosures([]);
    history.push("/marketing/promotions");
  };

  return (
    <Card
      title={"Add new promotion"}
      extra={
        <Space size={10}>
          <Button type="default" shape="round" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" shape="round" onClick={handleCreate} loading={isSaveLoading}>
            Save
          </Button>
        </Space>
      }
      className="page-container"
    >
      <Prompt when={formIsHalfFilledOut} message={LEAVE_CONFIRMATION} />
      <PromotionForm
        fromEdit={false}
        form={form}
        descriptionDisclosures={descriptionDisclosures}
        updateDescriptionDisclosures={setDescriptionDisclosures}
      />
    </Card>
  );
}

export default AddNewPromotion;
