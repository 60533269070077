import { SelectValue } from "antd/es/select";
import moment from "moment/moment";
import { Col, ConfigProvider, Form, FormInstance, Radio, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { DEFAULT_MAX_PAGINATION, validateMessages } from "../../../../../constants/common";
import styles from "./index.module.scss";
import {
  RecurlyCouponModel,
  DescriptionDisclosure,
  PromotionDiscountType,
} from "../../../../../types/model/promotionV2";
import * as basePriceAPI from "../../../../../services/basePrice";
import * as promotionAPI from "../../../../../services/promotionV2";
import { BasePriceModel, PriceStatus } from "../../../../../types/model/price";
import { getDisplayDiscount, getDisplayPromotionDiscountType, getDisplayPromotionStatus } from "../../util";
import { DescriptionAndDisclosure } from "../DescriptionAndDisclosure";
import * as eComProductAPI from "../../../../../services/eComProduct";
import { EComProductModel } from "../../../../../types/model/eComProduct";
import PromotionDetailsFormForRecurlySource from "./PromotionDetailsFormForRecurlySource";
import PromotionDetailsFormForEComSource from "./PromotionDetailsFormForEComSource";

export enum PromotionSource {
  RECURLY = "Recurly",
  ECOM = "eCom",
}

export interface Option {
  label: string;
  value: number;
}

interface PromotionFormProps {
  form: FormInstance;
  descriptionDisclosures: DescriptionDisclosure[];
  updateDescriptionDisclosures: (descriptionDisclosures: DescriptionDisclosure[]) => void;
  isEditing?: boolean;
}

export function PromotionForm({
  form,
  descriptionDisclosures,
  updateDescriptionDisclosures,
  isEditing = false,
}: PromotionFormProps) {
  const [promotionSource, setPromotionSource] = useState<PromotionSource>(PromotionSource.RECURLY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recurlyCoupons, setRecurlyCoupons] = useState<RecurlyCouponModel[]>([]);
  const [basePriceList, setBasePriceList] = useState<BasePriceModel[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [planOptionList, setPlanOptionList] = useState<Option[]>([]);
  const [giftPlanList, setGiftPlanList] = useState<EComProductModel[]>([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      promotionAPI.fetchAllValidRecurlyCoupons(),
      basePriceAPI.fetchAllCountries(),
      basePriceAPI.fetchBasePriceList({
        status: PriceStatus.ACTIVE,
        page: 1,
        limit: DEFAULT_MAX_PAGINATION.size,
      }),
      eComProductAPI.fetchProductList({
        limit: DEFAULT_MAX_PAGINATION.size,
        type: "GiftCode",
      }),
    ])
      .then(([allValidRecurlyCoupons, countries, basePriceList, giftPlanList]) => {
        setRecurlyCoupons(allValidRecurlyCoupons);
        setCountryList(countries.map((item) => ({ label: item.displayName, value: item.id })));
        setBasePriceList(basePriceList.list);
        setGiftPlanList(giftPlanList.list);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const currentPromotionSource = form.getFieldValue("source");
    setPromotionSource(currentPromotionSource);

    if (currentPromotionSource === PromotionSource.ECOM) {
      const { description, disclosure } = descriptionDisclosures[0];
      if (description) {
        description?.forEach((item) => {
          form.setFieldsValue({
            [`${item.locale}DescriptionContent`]: item.content,
          });
        });
      }
      if (disclosure) {
        disclosure?.forEach((item) => {
          form.setFieldsValue({
            [`${item.locale}DisclosureContent`]: item.content,
          });
        });
      }

      handleVariantsSearch(form.getFieldValue("basePlanPid")).then();
    }
  }, [descriptionDisclosures, form]);

  const handlePromotionSourceChange = (source: PromotionSource) => {
    setPromotionSource(source);
    form.resetFields();
    form.setFieldsValue({
      source,
    });

    if (source === PromotionSource.ECOM) {
      form.setFieldsValue({
        type: getDisplayPromotionDiscountType(PromotionDiscountType.BUY_ONE_GIFT_ONE),
      });
    }
  };

  const handlePromotionCodeChange = (selectedRecurlyCouponId: SelectValue) => {
    const coupon = recurlyCoupons.find((coupon) => coupon.id === selectedRecurlyCouponId);
    form.setFieldsValue({
      ...form?.getFieldsValue(),
      code: coupon!.code,
      recurlyCouponId: coupon!.id,
      name: coupon!.name,
      timeRange: [moment(coupon!.startedAt).local(), coupon!.endedAt ? moment(coupon!.endedAt).local() : null],
      type: getDisplayPromotionDiscountType(coupon!.discountType),
      discount: getDisplayDiscount(coupon!.discountType, coupon?.percent, coupon?.currencies),
      status: getDisplayPromotionStatus(coupon!.status),
    });
  };

  const handleVariantsSearch = async (search: string): Promise<Option[]> => {
    const res = await eComProductAPI.fetchProductVariantsByFuzzySearch(search);
    const options = res
      ? res.map(({ id, title, pid, billingPlan }) => ({
          key: id,
          label: billingPlan ? `${pid} ${title} / ${billingPlan.name}` : `${pid} ${title}`,
          value: pid,
        }))
      : [];
    setPlanOptionList(options);
    return options;
  };

  return (
    <Spin spinning={isLoading} size="large">
      <ConfigProvider form={{ validateMessages }}>
        <Form form={form} validateTrigger={"onBlur"} layout="vertical" className={styles.promotionForm}>
          <header className={styles.subtitle}>Promotion details</header>
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item
                label="Promotion source"
                name="source"
                rules={[{ required: true }]}
                initialValue={PromotionSource.RECURLY}
              >
                <Radio.Group onChange={(e) => handlePromotionSourceChange(e.target.value)} disabled={isEditing}>
                  {Object.values(PromotionSource).map((type) => (
                    <Radio value={type} key={type} data-testid={`promotion-source-${type}`}>
                      {type}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {promotionSource === PromotionSource.RECURLY && (
            <PromotionDetailsFormForRecurlySource
              recurlyCoupons={recurlyCoupons}
              basePriceList={basePriceList}
              countryList={countryList}
              handlePromotionCodeChange={handlePromotionCodeChange}
            />
          )}

          {promotionSource === PromotionSource.ECOM && (
            <PromotionDetailsFormForEComSource
              planOptionList={planOptionList}
              setPlanOptionList={setPlanOptionList}
              handleVariantsSearch={handleVariantsSearch}
              giftPlanList={giftPlanList}
            />
          )}
        </Form>

        {promotionSource === PromotionSource.RECURLY && (
          <DescriptionAndDisclosure
            editable={true}
            descriptionDisclosures={descriptionDisclosures}
            updateDescriptionDisclosures={updateDescriptionDisclosures}
          />
        )}
      </ConfigProvider>
    </Spin>
  );
}
