import React, { useState } from "react";
import moment from "moment";
import { message, Radio, RadioChangeEvent } from "antd";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import styles from "../Subscription.module.scss";
import { pauseSubscription } from "../../../../services/subscription";

export default function PauseSubscriptionModal({
  auth0Id,
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLength, setSelectedLength] = useState(1);

  async function onSubmit() {
    setIsLoading(true);
    try {
      await pauseSubscription(subscription.subscriptionId, auth0Id, selectedLength * 30, subscription.source);
      message.success("Pause successful");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } catch (e) {
      message.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EComModal
      title="Pause subscription"
      visible={visible}
      okText="Pause Subscription"
      cancelText="Keep learning"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <p>{content()}</p>
    </EComModal>
  );

  function content() {
    return (
      <div className={styles.pauseModal}>
        <span>* Choose the length of your pause</span>
        <Radio.Group
          defaultValue={selectedLength}
          onChange={(e: RadioChangeEvent) => {
            setSelectedLength(e.target.value as number);
          }}
        >
          <div>
            <Radio value={1}>
              <span className={selectedLength === 1 ? styles.radioBold : ""}>1 month</span>
            </Radio>
            <Radio value={2}>
              <span className={selectedLength === 2 ? styles.radioBold : ""}>2 months</span>
            </Radio>
          </div>
        </Radio.Group>
        <p>
          The pause will take effect on {moment(subscription.nextBilling?.date).format("MM/DD/YYYY")} and will end on{" "}
          {pauseEndDate()}. The user may continue to use Pimsleur until the pause begins. The user will not be billed
          for the duration of your pause. Unless the user cancel, the next billing date will be {pauseEndDate()}.
        </p>
      </div>
    );
  }

  function pauseEndDate() {
    const date = moment(subscription.nextBilling?.date);
    const endDate = date.add(selectedLength * 30, "day");
    return endDate.format("MM/DD/YYYY");
  }
}
