import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import ProductModel from "types/model/product";
import { DATETIME_FORMAT, PRODUCT_TYPE_VIEW_MAPPING } from "constants/common";
import ProductStatusView from "components/ProductStatus";
import { Alarm } from "assets/icons";

function useColumns(sortOrder: SortOrder): ColumnType<ProductModel>[] {
  return [
    {
      title: "ISBN",
      dataIndex: "isbn",
      width: 120,
      ellipsis: true,
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <div>{record.isbn}</div>
          {record.scheduledPublishTime && (
            <Tooltip title={moment(record.scheduledPublishTime).format()}>
              <Alarm style={{ marginLeft: "5px" }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 75,
      ellipsis: true,
      render: (value) => <ProductStatusView status={value} />,
    },
    {
      title: "Course type",
      dataIndex: "isPremium",
      width: 80,
      ellipsis: true,
      render: (value: boolean) => (value ? "Premium" : "Standard"),
    },
    {
      title: "Language",
      dataIndex: "languageName",
      width: 90,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Course name - Upsell card & Library",
      dataIndex: "courseNameUpsellCardLibrary",
      width: 195,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Version",
      dataIndex: "version",
      width: 85,
      ellipsis: true,
      render: (value) => value || "-",
    },
    {
      title: "Product type",
      dataIndex: "productType",
      width: 85,
      ellipsis: true,
      render: (_, { productType }) => PRODUCT_TYPE_VIEW_MAPPING[productType] || "-",
    },
    {
      title: "Modified at",
      key: "lastModifiedDate",
      dataIndex: "lastModifiedDate",
      width: 110,
      ellipsis: true,
      defaultSortOrder: "descend",
      sortOrder: sortOrder,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => moment(value).format(DATETIME_FORMAT),
    },
  ];
}

export default useColumns;
