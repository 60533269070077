import { Input } from "antd";
import React from "react";

interface IProps {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

export const PromotionCode = ({ disabled = false, value = "", onChange, onBlur }: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    onChange && onChange(filteredValue);
  };

  return (
    <Input.Group compact={true} onBlur={onBlur}>
      <Input style={{ width: "70%" }} maxLength={19} disabled={disabled} value={value} onChange={handleChange} />
      <Input style={{ textAlign: "center", width: "30%" }} disabled value={"******"} />
    </Input.Group>
  );
};
