import { ColumnType } from "antd/es/table";
import moment from "moment";
import { capitalize, startCase } from "lodash";
import { CodeUsageType, DiscountType, PromotionModel, PromotionStatus } from "../../../../types/model/promotion";
import { DATETIME_FORMAT_WITH_MINUTE, EMPTY } from "../../../../constants/common";
import CustomerDot from "../../../../components/CustomDot";

export default function getPromotionTableColumns(): ColumnType<PromotionModel>[] {
  return [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 220,
      fixed: "left",
      ellipsis: true,
      render: (text: string) => text,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 120,
      render: (status: PromotionStatus) => {
        return <CustomerDot color={status} />;
      },
    },
    {
      title: "Code usage",
      key: "codeUsage",
      dataIndex: "codeUsage",
      width: 120,
      ellipsis: true,
      render: (text: string) => startCase(text),
    },
    {
      title: "Code",
      key: "code",
      dataIndex: "code",
      width: 160,
      ellipsis: true,
      render: (_, { code, codeUsage }) => `${code}${codeUsage === CodeUsageType.SINGLE_USE ? "******" : ""}`,
    },
    {
      title: "Applied base price",
      key: "basePrices",
      width: 220,
      ellipsis: true,
      render: (_, { basePrices, discountType }) => {
        if (discountType === DiscountType.BUY_ONE_GIFT_ONE) {
          return EMPTY;
        }
        return basePrices?.map((basePrice) => basePrice.name).join(", ");
      },
    },
    {
      title: "Promotion Type",
      key: "discountType",
      dataIndex: "discountType",
      width: 150,
      ellipsis: true,
      render: (discountType: DiscountType) => capitalize(discountType),
    },
    {
      title: "Discount",
      key: "discount",
      dataIndex: "discountType",
      width: 120,
      ellipsis: true,
      render: (discountType: DiscountType, record: PromotionModel) => {
        if (discountType === DiscountType.PERCENTAGE && record.percentageDiscount) {
          return `${record.percentageDiscount}%`;
        } else if (discountType === DiscountType.AMOUNT && record.amountDiscount && record.currencyCode) {
          return `${record.currencyCode} ${record.amountDiscount}`;
        }
        return EMPTY;
      },
    },
    {
      title: "Time range",
      key: "timeRange",
      dataIndex: "startAt",
      width: 312,
      ellipsis: true,
      render: (_value: string, record: PromotionModel) => {
        const localStart = moment(record.startAt).local().format(DATETIME_FORMAT_WITH_MINUTE);
        const localEnd = moment(record.endAt).local().format(DATETIME_FORMAT_WITH_MINUTE);
        return `${localStart} to ${localEnd}`;
      },
    },
  ];
}
