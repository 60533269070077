import React from "react";
import { Col, Divider, Form, Row } from "antd";
import DisplayCopyInput from "components/DisplayCopyInput";
import { ProductStatus } from "types/model/product";
import { DisplayInput, ProductStatusView } from "../../../../components";
import ViewDeeplinkModal from "./ViewDeeplinkModal";
import { DisplayProductImage } from "./DisplayProductImage";
import { EComProductStatus, FreeTrialType, PRODUCT_TYPE } from "../../../../types/model/eComProduct";

interface IPlanDetailsLayout {
  basePriceHelpText?: string;
  planImageUrl?: string;
  pid?: string;
  status?: string;
  descriptionHelpText?: string;
  freeTrialType?: FreeTrialType;
  freeTrialUsageLimit?: number;
  contentProductType: string;
}

const DetailContainerComponent = ({
  basePriceHelpText,
  planImageUrl,
  pid,
  status,
  descriptionHelpText,
  freeTrialType,
  freeTrialUsageLimit,
  contentProductType,
}: IPlanDetailsLayout) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="name" label="Plan Name:">
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="pid" label="PID:">
            {pid ? <DisplayCopyInput /> : <DisplayInput />}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="courseName" label="Course:">
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="contentProductType" label="Content Product Type:">
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="contentProductStatus" label="Content Product Status:">
            <DisplayInput<ProductStatus> render={(x) => x && <ProductStatusView status={x} />} />
          </Form.Item>
        </Col>
        {contentProductType === PRODUCT_TYPE.MULTI_LEVEL && (
          <Col span={6}>
            <Form.Item name="singleLevelProduct" label="Single Level Product:">
              <DisplayInput />
            </Form.Item>
          </Col>
        )}
        {contentProductType === PRODUCT_TYPE.UPGRADE && (
          <Col span={12}>
            <Form.Item name="singleLevelProduct" label="Premium Content ISBN:">
              <DisplayInput />
            </Form.Item>
          </Col>
        )}
      </Row>
      {contentProductType === PRODUCT_TYPE.SINGLE_LEVEL && (
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="courseVersion" label="Version:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="courseLevel" label="Course Level:">
              <DisplayInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="lessonRange" label="Lesson Range:">
              <DisplayInput />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Divider />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="basePriceName" label="Base Price:" help={basePriceHelpText}>
            <DisplayInput />
          </Form.Item>
        </Col>
        {(contentProductType === PRODUCT_TYPE.SUBSCRIPTION || contentProductType === PRODUCT_TYPE.ALL_ACCESS) && (
          <Col span={6}>
            <Form.Item name="billingPlanName" label="Billing Plan:">
              <DisplayInput />
            </Form.Item>
          </Col>
        )}
        {(contentProductType === PRODUCT_TYPE.SUBSCRIPTION || contentProductType === PRODUCT_TYPE.ALL_ACCESS) && (
          <Col span={6}>
            <Form.Item name="billingQuantityAndCycle" label="Billing Cycle:">
              <DisplayInput />
            </Form.Item>
          </Col>
        )}
        {(contentProductType === PRODUCT_TYPE.SUBSCRIPTION || contentProductType === PRODUCT_TYPE.ALL_ACCESS) &&
          freeTrialType && (
            <Col span={6}>
              <Form.Item name="freeTrialType" label="Free trial type:">
                <DisplayInput />
              </Form.Item>
            </Col>
          )}
        {(contentProductType === PRODUCT_TYPE.SUBSCRIPTION || contentProductType === PRODUCT_TYPE.ALL_ACCESS) &&
          freeTrialUsageLimit && (
            <Col span={6}>
              <Form.Item name="freeTrialUsageLimit" label="Free trial usage limit:">
                <DisplayInput />
              </Form.Item>
            </Col>
          )}
        <Col span={12}>
          <Form.Item name="joinUpsell" label="Join Upsell:">
            <DisplayInput />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="descTemplateName" label="Description Template:" help={descriptionHelpText}>
            <DisplayInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="checkoutDeeplink" label="Checkout Deeplink:">
            {status === EComProductStatus.ReadyForPublish || status === EComProductStatus.Active ? (
              pid ? (
                <DisplayCopyInput textStyle={{ maxWidth: 700 }} />
              ) : (
                <DisplayInput />
              )
            ) : (
              "-"
            )}
          </Form.Item>
          {(status === EComProductStatus.ReadyForPublish || status === EComProductStatus.Active) && pid && (
            <ViewDeeplinkModal pid={pid} />
          )}
        </Col>
        <Col span={12}>
          <Form.Item name="productImage" label="Plan Image:">
            <DisplayProductImage imageUrl={planImageUrl} />
          </Form.Item>
        </Col>
        {(status === EComProductStatus.ReadyForPublish || status === EComProductStatus.Active) && (
          <Col span={12}>
            <Form.Item name="promotionCode" label="Available promo code:">
              <DisplayInput />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default DetailContainerComponent;
